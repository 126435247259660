<template>
  <div ref="scoll">
    <el-card class="box-card">
      <h4>渲染器使用情况</h4>
      <!-- <p class="box-fsP">渲染到期时间：2022年7月13日18：42：23</p> -->
      <div class="box-flex">
        <div class="box-flex-one">
          <!-- <el-button plain v-if="loadSituation == 0" size="mini" type="success">畅通</el-button>
                    <el-button plain v-if="loadSituation == 1" size="mini" type="primary">正常</el-button>
                    <el-button plain v-if="loadSituation == 2" size="mini" type="danger">繁忙</el-button> -->
          <el-tag v-if="loadSituation == 0" size="medium" type="success">畅通</el-tag>
          <el-tag v-if="loadSituation == 1" size="medium">正常</el-tag>
          <el-tag v-if="loadSituation == 2" size="medium" type="danger">繁忙</el-tag>
          <p>当前服务器荷载水平</p>
        </div>
        <div class="box-flex-one">
          <p class="box-flex-one-bole">{{ stayRenderNumber }}张</p>
          <p>当前待渲染</p>
        </div>
        <div class="box-flex-one">
          <p class="box-flex-one-bole">{{ renderingNumber }}张</p>
          <p>当前渲染中</p>
        </div>
        <div class="box-flex-one">
          <p class="box-flex-one-bole">{{ completeNumber }}张</p>
          <p>累计已渲染方案</p>
        </div>
        <div class="box-flex-one">
          <p class="box-flex-one-bole">{{ distributionNumber }}张</p>
          <p>当前分配</p>
        </div>
      </div>
    </el-card>
    <el-card class="box-card">
      <div class="box-cardOne-flex">
        <h4>渲染器任务</h4>
        <div style="width: 460px; display: flex; justify-content: space-between">
          <el-input
            v-model="from.account"
            size="mini"
            placeholder="请输入内容"
            style="width: 200px"
          ></el-input>
          <el-select size="mini" v-model="from.state" placeholder="按状态筛选" clearable>
            <el-option
              v-for="item in stateLsit"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-button size="mini" type="info" @click="inquire">查询</el-button>
        </div>
      </div>
      <div class="box-body" v-for="(item, i) in renderStatus" :key="i">
        <div class="box-body-img">
          <div class="box-body-img-left">
            <img style="width: 100%; height: 100%" :src="item.browse_path" alt="" />
            <el-progress
              v-if="item.status == 2 && item.data"
              :text-inside="true"
              :stroke-width="10"
              :percentage="item.data.Schedule * 1"
            ></el-progress>
          </div>
          <div class="box-body-img-right">
            <div style="display: flex; align-items: center">
              <h5 style="margin: 0; font-size: 16px">{{ item.image_name }}</h5>
              <!-- <span style="margin-left: 20px;">发起渲染时间：2019.08.29
                                22:26:97</span> -->
            </div>
            <p style="display: flex; align-items: center">
              发起账号：
              <img
                style="
                  width: 20px;
                  height: 20px;
                  border-radius: 20px;
                  overflow: hidden;
                  margin: 0 7px;
                  object-fit: cover;
                "
                :src="item.avatar"
                alt=""
              />
              {{ item.username }}
            </p>
            <!-- <p>预计剩余渲染时间/01:22:33</p> -->
            <p>发起渲染时间：{{ item.create_time }}</p>
            <!-- <el-button v-if="item.statusName == '渲染中'" size="mini" type="warning" plain>渲染中</el-button>
                        <el-button v-if="item.statusName == '等待中'" size="mini" type="success" plain>等待中</el-button>
                        <el-button v-if="item.statusName == '已取消'" size="mini" type="info" plain>已取消</el-button> -->
            <el-tag v-if="item.statusName == '渲染中'" size="medium" type="warning">渲染中</el-tag>
            <el-tag v-if="item.statusName == '等待中'" size="medium" type="success">等待中</el-tag>
            <el-tag v-if="item.statusName == '已取消'" size="medium" type="info">已取消</el-tag>
            <span style="font-size: 16px; color: black" v-if="item.statusName == '渲染中'">
              {{ item.data.Timeleft }}
            </span>
          </div>
        </div>
        <div>
          <el-button
            style="width: 160px"
            type="info"
            v-if="item.status != -1"
            @click="abolish(item)"
          >
            取消渲染
          </el-button>
        </div>
      </div>
      <div style="text-align: center; margin: 25px 0">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.page"
          :page-sizes="[10, 15, 30, 35]"
          :page-size="page.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.totalItems"
        ></el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
// import { cloudspaceSetsave } from "../../common/renderer";
import {
  render_list,
  lineUp_list,
  cancel_render,
  // picture_zoom,
} from '../../common/renderer';
export default {
  name: 'renderer',
  data() {
    return {
      // 按状态筛选列表
      stateLsit: [],
      // 搜索
      from: {
        account: '',
        state: '',
      },
      value: '',
      // 分页
      page: {
        page: 1,
        pageSize: 10,
        totalItems: 0,
      },
      // 头部列表数据
      stayRenderNumber: 0,
      renderingNumber: 0,
      completeNumber: 0,
      distributionNumber: 0,
      list: {},
      // 渲染列表
      renderStatus: [],
      // 状态
      loadSituation: 0,
      // 两秒一次定时器
      againTime: null,
    };
  },
  created() {
    clearTimeout(this.againTime);
    this.init();
    this.openTime();
  },
  beforeDestroy() {
    clearTimeout(this.againTime);
    // clearInterval(this.againTime)
  },
  methods: {
    // 开启定时器
    openTime() {
      this.againTime = setTimeout(() => {
        clearTimeout(this.againTime);
        this.init('off');
      }, 2000);
      // this.againTime = setInterval(() => {
      //     this.init('off')
      // }, 2000);
    },
    // 分页
    handleSizeChange(size) {
      clearTimeout(this.againTime);
      this.page.pageSize = size;
      this.returnTop();
      this.init();
    },
    handleCurrentChange(page) {
      clearTimeout(this.againTime);
      this.page.page = page;
      this.returnTop();
      this.init();
    },
    // 查询按钮
    inquire() {
      this.page.page = 1;
      this.page.pageSize = 10;
      this.init();
    },
    returnTop() {
      document.querySelector('.el-main').scrollTop = 0;
    },
    // 取消渲染按钮
    abolish(e) {
      this.$confirm('此操作将取消渲染, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          cancel_render({ render_id: e.render_id }).then((res) => {
            let { code, msg } = res;
            if (code == 200) {
              this.$message.warning(msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已放弃操作',
          });
        });
    },
    // 删除按钮
    // del() {
    //     this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
    //         confirmButtonText: '确定',
    //         cancelButtonText: '取消',
    //         type: 'warning'
    //     }).then(() => {
    //         this.$message.error('已删除')
    //     }).catch(() => {
    //         this.$message({
    //             type: 'info',
    //             message: '已取消删除'
    //         });
    //     });
    // },
    // 初始化请求
    init(off = '') {
      clearTimeout(this.againTime);
      let dt = {
        page: this.page.page,
        limit: this.page.pageSize,
        account: this.from.account,
        status: this.from.state,
      };
      render_list(dt, off).then((res) => {
        // console.log(res);
        clearTimeout(this.againTime);
        let { code, result } = res;
        if (code == 200) {
          clearTimeout(this.againTime);
          this.stayRenderNumber = result.stayRenderNumber;
          this.renderingNumber = result.renderingNumber;
          this.completeNumber = result.completeNumber;
          this.distributionNumber = result.distributionNumber;
          this.list = result.list;
          this.stateLsit = result.renderStatus;
          this.renderStatus = result.list.data;
          this.page.totalItems = result.list.total;
          this.loadSituation = result.loadSituation.status;
          let arr = [];
          this.renderStatus.forEach((el) => {
            if (el.status != -1) {
              arr.push(el.render_id);
            }
          });
          if (arr.length > 0) {
            lineUp_list({ render: arr }).then((res) => {
              // console.log(res);
              let { code, result } = res;
              if (code == 200) {
                this.renderStatus.forEach((el, i) => {
                  let ad = result.filter((item) => {
                    return item.render_id == el.render_id;
                  });
                  if (ad.length > 0) {
                    this.renderStatus[i]['data'] = ad[0];
                  }
                });
                clearTimeout(this.againTime);
                this.openTime();
              }
            });
          } else {
            clearTimeout(this.againTime);
            // clearInterval(this.againTime)
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.box-card {
  margin: 15px;

  h4 {
    margin: 0px 0;
  }

  .box-fsP {
    font-size: 14px;
    text-align: right;
    margin-top: 0;
  }

  .box-flex {
    display: flex;
    align-items: flex-end;
    font-size: 14px;
    margin-top: 10px;

    .box-flex-one {
      text-align: center;
      margin: 0 30px;

      .box-flex-one-bole {
        font-weight: bold;
        font-size: 18px;
      }
    }
  }

  .box-cardOne-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #bbb;
    padding-bottom: 20px;
  }

  .box-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 20px 0;
    border-bottom: 1px solid #bbb;

    .box-body-img {
      display: flex;

      .box-body-img-left {
        width: 200px;
        height: 120px;
        font-size: 0;

        .el-progress {
          // border: 1px solid #000;
          margin: 0;
          padding: 0;

          ::v-deep .el-progress-bar__outer {
            border-radius: 0;

            .el-progress-bar__inner {
              border-radius: 0;
              font-size: 12px;
              line-height: 0;
            }
          }
        }
      }

      .box-body-img-right {
        margin-left: 20px;
        font-size: 13px;
      }
    }
  }
}
</style>
