import $http from '@/utils/http';

/**
 * 取消渲染
 * @param data
 * @returns {Promise<unknown>}
 */
export function cancel_render(data) {
  return $http.post('/api/cancel_render', data, 'loadingDiv');
}

/**
 * 渲染列表
 * @param data
 * @returns {Promise<unknown>}
 */
export function render_list(data, off) {
  return $http.get('/api/render_list', data, 'loadingDiv', off);
}

/**
 * 获取排队信息
 * @param data
 * @returns {Promise<unknown>}
 */
export function lineUp_list(data) {
  return $http.get('/api/lineUp_list', data, 'loadingDiv', 'off');
}
